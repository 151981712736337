/**
 * =============================================================================
 * ************   Tab   ************
 * =============================================================================
 *
 * 在手机上选项卡始终平分或可滚动，在平板以上的设备上默认左对齐，可以选择居中对齐，或全宽等分
 */

/* 选项卡，默认的选项卡为全宽 */
.mdui-tab {
  display: flex;
  position: relative;
  overflow-x: auto;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
  min-height: 48px;
  max-height: 72px;
  padding: 0;
  margin: 0 auto;
  white-space: nowrap;

  a {
    display: flex;
    color: inherit;
    text-decoration: none;
    font-size: 14px;

    box-sizing: border-box;
    text-align: center;
    min-height: 48px;
    max-height: 72px;
    cursor: pointer;
    text-transform: uppercase;
    text-overflow: ellipsis;
    overflow: hidden;
    user-select: none;
    opacity: 0.7;
    padding: 12px;
    min-width: 72px;
    outline: none;
    // 手机上始终等分选项卡
    flex: 1;

    // 使图标和文本垂直排列
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .mdui-icon {
      opacity: 0.7;
    }

    // 平板或以上设备
    @media (min-width: @screen-sm-min) {
      min-width: 99px;
      max-width: 264px;

      // 手机上 padding 12px，平板/pc上 padding 24px
      padding: 12px 24px;

      // 平板/pc上选项卡左对齐
      flex: none;
    }

    @media (min-width: @screen-md-min) {
      min-width: 112px;
    }

    @media (min-width: @screen-lg-min) {
      min-width: 136px;
    }

    @media (min-width: @screen-xl-min) {
      min-width: 160px;
    }

    label {
      cursor: pointer;
      width: 100%;
      display: block;
    }

    .mdui-icon + label {
      margin-top: 8px;
    }

    &[disabled] {
      opacity: 0.38;
      cursor: default;

      label {
        cursor: default;
      }
    }
  }

  // 激活状态的选项
  .mdui-tab-active {
    color: @color-default-500;
    opacity: 1;
    .mdui-icon {
      opacity: 1;
    }
  }
}

/* 选项卡居中 */
.mdui-tab-centered {
  @media (min-width: @screen-sm-min) {
    &:before {
      content: ' ';
      flex-grow: 1;
    }
    &:after {
      content: ' ';
      flex-grow: 1;
    }
    a {
      flex: none;
    }
  }
}

/* 选项卡始终全宽等分 */
.mdui-tab-full-width {
  a {
    max-width: none;
    flex: 1;
  }
}


/* 可横向滚动的选项卡 */
.mdui-tab-scrollable {
  padding-left: 56px;

  a {
    flex: none;
  }

  @media (max-width: @screen-xs-max) {
    padding-left: 60px;
  }

}

/* 下划线指示器 */
.mdui-tab-indicator {
  position: absolute;
  bottom: 0;
  height: 2px;
  background-color: @color-default-500;
  will-change: left, width;
  transition: all .35s @animation-curve-default;
}

/**
 * =============================================================================
 * ************   Tab 主色   ************
 * =============================================================================
 */

& {
  .loop-primary-theme(@counter-color) when (@counter-color > 0) {
    .loop-primary-theme((@counter-color - 1));
    @colorName: extract(@globalPrimaryColors, @counter-color);
    @color: 'color-@{colorName}-500';

    .mdui-theme-primary-@{colorName} {
      .mdui-tab .mdui-tab-active {
        color: @@color;
      }

      .mdui-tab-indicator {
        background-color: @@color;
      }
    }

  }
  .loop-primary-theme(length(@globalPrimaryColors));
}

/* 带背景色的选项卡 */
.mdui-tab[class*="mdui-color-"]:not(.mdui-color-white) {
  .mdui-tab-active {
    color: inherit;
  }
  .mdui-tab-indicator {
    background-color: @color-white-500;
  }

  // 带背景色的选项卡中使用浅色滚动条
  & {
    .mdui-scrollbar-beautify(dark);
  }
}
